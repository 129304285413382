// External Imports
import React from "react";
import { View, Text, Linking, Platform } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import Toast from "react-native-toast-message";
// Internal Imports
import InputText from "../../../components/Inputs/InputText";
import { selectUser, setUser } from "../../../mainRedux/slices/userSlice";
import Button from "../../../components/Buttons/Button";
import { updateMe } from "../../../controllers/meController";
import Link from "../../../components/Buttons/Link";
// ProfileEditBody Component
const ProfileEditBody = () => {
  // Objects
  const dispatch = useDispatch();
  // States
  const user = useSelector(selectUser);
  const [fullName, setFullName] = React.useState(null);
  const [username, setUsername] = React.useState(null);
  const [email, setEmail] = React.useState(null);
  const [phoneNumber, setPhoneNumber] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  // Refs
  const fullNameRef = React.useRef();
  const usernameRef = React.useRef();
  const emailRef = React.useRef();
  const phoneNumberRef = React.useRef();
  // Set Refs on submit
  const handleSuivantPress = (nextRef) => {
    // Move focus to the next input field
    if (nextRef && nextRef.current) {
      nextRef.current.focus();
    }
  };
  // Functions
  const handleEditProfile = async () => {
    if (isLoading) return;
    setIsLoading(true);
    // Try to update profile
    const res = await updateMe(user, {
      fullName,
      username,
      email,
      phoneNumber,
    });
    setIsLoading(false);
    // Check if success
    if (res.success) {
      Toast.show({
        type: "success",
        text1: res.message,
        visibilityTime: 3000,
        autoHide: true,
      });
      dispatch(setUser(res.data));
      return;
    }
    // Check if error
    Toast.show({
      type: "error",
      text1: res.message,
      visibilityTime: 5000,
      autoHide: true,
    });
  };
  const handleEditPassword = () => {
    Linking.openURL("https://studybridge.ca/reset-password/");
  };
  return (
    <View
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={{
        padding: 30,
        width: "100%",
        marginTop: 30,
      }}>
      <Text
        style={{
          fontSize: 25,
          fontWeight: "bold",
          width: "100%",
          color: "#5D5DFF",
          marginBottom: 10,
          alignSelf: "center",
        }}>
        Modification du Profile
      </Text>
      <View
        style={{
          width: "100%",
          marginBottom: 10,
        }}>
        <InputText
          label="Nom complet"
          firstValue={user?.fullName}
          setData={setFullName}
          ref={fullNameRef}
          onSubmitEditing={() => handleSuivantPress(usernameRef)}
        />
        <InputText
          label="Nom d'utilisateur"
          firstValue={user?.username}
          setData={setUsername}
          ref={usernameRef}
          onSubmitEditing={() => handleSuivantPress(emailRef)}
        />
        <InputText
          label="E-mail"
          firstValue={user?.email}
          setData={setEmail}
          ref={emailRef}
          onSubmitEditing={() => handleSuivantPress(phoneNumberRef)}
        />
        <InputText
          label="Téléphone"
          firstValue={user?.phoneNumber}
          setData={setPhoneNumber}
          keyBoardType="numeric"
          ref={phoneNumberRef}
        />
        <Button
          label="Enregistrer"
          isLoading={isLoading}
          onPress={handleEditProfile}
          isFullWidth={true}
        />
        <View style={{ height: 20 }} />
        <Link
          label={"Modifier le mot de passe ?"}
          onPress={handleEditPassword}
        />
      </View>
    </View>
  );
};
export default ProfileEditBody;
