// Externals Imports
import React from "react";
import { Text, TouchableOpacity, View, Modal as RNModal } from "react-native";
// Modal Component
const Modal = ({ title, description, buttons, isVisible, onClose }) => {
  // Functions
  const renderButtons = () => {
    return buttons.map((button, index) => (
      <TouchableOpacity
        key={index}
        onPress={() => {
          button.onPress && button.onPress();
          onClose();
        }}
        style={{
          padding: 10,
          margin: 5,
          backgroundColor: "#5D5DFF",
          borderRadius: 5,
        }}>
        <Text style={{ color: "#fff", fontWeight: "700" }}>{button.text}</Text>
      </TouchableOpacity>
    ));
  };

  return (
    <RNModal visible={isVisible} transparent animationType="slide">
      <TouchableOpacity
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0,0,0,0.5)",
        }}
        onPress={onClose} // Fermer le modal lorsque l'utilisateur appuie à l'extérieur
      >
        <TouchableOpacity
          // Empecher la fermeture du modal lorsque l'utilisateur appuie à l'intérieur et aussi je veux de changement de l'opacité
          activeOpacity={1}
          onPress={() => {}}
          style={{
            backgroundColor: "#fff",
            paddingHorizontal: 20,
            paddingTop: 20,
            borderRadius: 10,
            shadowColor: "#000",
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 3.84,
            elevation: 5,
          }}>
          <Text
            style={{
              fontSize: 20,
              fontWeight: "bold",
              color: "#5D5DFF",
              marginBottom: 10,
            }}>
            {title}
          </Text>
          <Text>{description}</Text>
          <View
            style={{
              flexDirection: "row",
              marginTop: 10,
              justifyContent: "center",
            }}>
            {renderButtons()}
          </View>
        </TouchableOpacity>
      </TouchableOpacity>
    </RNModal>
  );
};
// Exports
export default Modal;
