// External Imports
import { configureStore } from "@reduxjs/toolkit";
// Internal Imports
import userReducer from "./slices/userSlice";
import publicationsReducer from "../features/home/redux/publicationSlice";
// Main Store
export const mainStore = configureStore({
  reducer: {
    user: userReducer,
    publications: publicationsReducer,
  },
});
