// Externals imports
import React from "react";
import { View, Text, Image, TouchableOpacity, Platform } from "react-native";
import { useSelector } from "react-redux";
import { Feather } from "@expo/vector-icons";
// Internal Imports
import { selectUser } from "../../../mainRedux/slices/userSlice";
import Link from "../../../components/Buttons/Link";
// ProfileHeader Component
const ProfileHeader = () => {
  // States
  const user = useSelector(selectUser);
  // Functions
  const handleEditProfilePicture = () => {
    alert("Comming soon...");
  };
  const handleEditMoreDetails = () => {
    alert("Comming soon...");
  };
  // Component
  return (
    <View
      style={{
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
      }}>
      <View>
        <Text style={{ fontSize: 30, fontWeight: "bold", color: "#5D5DFF" }}>
          Profile
        </Text>
        <TouchableOpacity onPress={handleEditProfilePicture}>
          <Image
            style={{
              width: 100,
              height: 100,
              resizeMode: "cover",
              borderRadius: 50,
              borderColor: "#5D5DFF",
              borderWidth: 2,
            }}
            source={{
              uri:
                process.env.NODE_ENV === "development"
                  ? process.env.URL_IMAGES_DEV + user.profilePicture
                  : process.env.URL_IMAGES_PROD + user.profilePicture,
            }}
          />
          <Feather
            name="edit"
            size={18}
            color="black"
            style={{
              position: "absolute",
              bottom: 0,
              right: 0,
              backgroundColor: "#5D5DFF",
              color: "#fff",
              borderRadius: 50,
              padding: 7,
            }}
          />
        </TouchableOpacity>
      </View>
      <View>
        <Text
          style={{
            fontSize: 20,
            fontWeight: "bold",
            color: "#5D5DFF",
            marginLeft: 20,
          }}>
          {user?.username}
        </Text>
        <Text
          style={{
            fontSize: 16,
            fontWeight: "bold",
            marginLeft: 20,
          }}>
          {user?.email}
        </Text>
        {user?.fullName && (
          <Text
            style={{
              fontSize: 16,
              marginLeft: 20,
            }}>
            {user?.fullName}
          </Text>
        )}
        {user?.phoneNumber && (
          <Text
            style={{
              fontSize: 16,
              marginLeft: 20,
            }}>
            {user?.phoneNumber}
          </Text>
        )}
        {(user?.role === "admin" || user?.role === "tuto") && (
          <View
            style={{
              marginTop: 20,
              width: "100%",
              alignItems: "center",
            }}>
            <Link label={"Plus de détails"} onPress={handleEditMoreDetails} />
          </View>
        )}
      </View>
    </View>
  );
};
export default ProfileHeader;
