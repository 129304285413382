// Me Controllers
// Externals Imports
import axios from "axios";
// Internals Imports
import SecureLocalStorageManager from "../utils/localStorageManager";
// Variables
const meUrl =
  process.env.NODE_ENV === "development"
    ? process.env.URL_DEV
    : process.env.URL_PROD;
// @desc    Get current user profile
// @route   GET /api/v1/me
export const getMe = async () => {
  try {
    // Get token from local storage
    const token = await SecureLocalStorageManager.get("token");
    if (!token) return { success: false };
    // Get Me
    const res = await axios.get(`${meUrl}/me`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // Return Me
    return res.data;
  } catch (err) {
    // Return Error
    return err.response.data;
  }
};
// @desc    Update current user profile
// @route   PUT /api/v1/me
export const updateMe = async (user, newData) => {
  try {
    // Check if there is no changes between old data and new data
    if (
      (newData.fullName === user.fullName &&
        newData.username === user.username &&
        newData.email === user.email &&
        newData.phoneNumber === user.phoneNumber) ||
      (!newData.fullName &&
        !newData.username &&
        !newData.email &&
        !newData.phoneNumber)
    )
      return {
        success: false,
        message: "Aucune modification n'a été effectuée",
      };
    // console.log("newData", newData);
    // console.log("user", user);
    // Create data object
    const data = {};
    if (newData.fullName !== user.fullName) data.fullName = newData.fullName;
    if (newData.username !== user.username) data.username = newData.username;
    if (newData.email !== user.email) data.email = newData.email;
    if (newData.phoneNumber !== user.phoneNumber)
      data.phoneNumber = newData.phoneNumber;
    // Get token from local storage
    const token = await SecureLocalStorageManager.get("token");
    if (!token)
      return { success: false, message: "Erreur Interne reconnectez vous" };
    // Update Me
    const res = await axios.put(`${meUrl}/me`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // Return Me
    return res.data;
  } catch (err) {
    // Return Error
    return err.response.data;
  }
};
