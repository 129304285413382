// External Imports
import React from "react";
import { StatusBar } from "expo-status-bar";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { NavigationContainer } from "@react-navigation/native";
// Internals Imports
import AuthStack from "./features/auth/AuthStack";
import { Provider } from "react-redux";
import { mainStore } from "./mainRedux/mainStore";
// App Component
export default function App() {
  return (
    <SafeAreaProvider>
      <NavigationContainer>
        <Provider store={mainStore}>
          {/* Home Stack A l'interieur de Auth Stack */}
          <AuthStack />
        </Provider>
      </NavigationContainer>
      <StatusBar style="auto" />
    </SafeAreaProvider>
  );
}
