// Externals Imports
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { useEffect } from "react";
import { useNavigation } from "@react-navigation/native";
import { AntDesign, Feather, SimpleLineIcons } from "@expo/vector-icons";
// Internals Imports
import HomeScreen from "./screens/HomeScreen";
import ProfileScreen from "./screens/ProfileScreen";
import SecureLocalStorageManager from "../../utils/localStorageManager";
import OptionsScreen from "./screens/OptionsScreen";
import EditPublicationScreen from "./screens/SubScreens/EditPublicationScreen";
// Create Tab Navigator
const Tab = createBottomTabNavigator();
// Create HomeTab
const HomeTab = () => {
  return (
    <Tab.Navigator
      initialRouteName="HomeScreen"
      screenOptions={{
        headerShown: false,
        activeTintColor: "#5D5DFF",
      }}>
      <Tab.Screen
        name="HomeScreen"
        component={HomeScreen}
        options={{
          tabBarIcon: ({ color, size }) => (
            <AntDesign name="home" size={size} color={color} />
          ),
          title: "Accueil",
        }}
      />
      <Tab.Screen
        name="ProfileScreen"
        // Delete title
        component={ProfileScreen}
        options={{
          tabBarIcon: ({ color, size }) => (
            <Feather name="user" size={size} color={color} />
          ),
          title: "Profile",
        }}
      />
      <Tab.Screen
        name="OptionsScreen"
        component={OptionsScreen}
        options={{
          tabBarIcon: ({ color, size }) => (
            <SimpleLineIcons name="list" size={size} color={color} />
          ),
          title: "Options",
        }}
      />
      <Tab.Screen
        name="Logout"
        component={Logout}
        options={{
          tabBarIcon: ({ color, size }) => (
            <AntDesign name="logout" size={size} color={color} />
          ),
          title: "Déconnexion",
        }}
      />
      <Tab.Screen
        name="EditPublicationScreen"
        component={EditPublicationScreen}
        options={{
          tabBarButton: () => null,
        }}
      />
    </Tab.Navigator>
  );
};
// Logout Component
const Logout = () => {
  const nav = useNavigation();
  useEffect(() => {
    SecureLocalStorageManager.remove("token");
    nav.navigate("Login");
  }, []);
  return null;
};
// Export HomeTab
export default HomeTab;
