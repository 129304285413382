// External imports
import React from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  Linking,
  useState,
  Platform,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { useSelector } from "react-redux";
// Internal imports
import Link from "../../../components/Buttons/Link";
import { selectUser } from "../../../mainRedux/slices/userSlice";
import Button from "../../../components/Buttons/Button";
import Modal from "../../../components/Modal/Modal";
// PublicationHeader Component
const PublicationHeader = ({ Publication, editButton = true }) => {
  // Objects
  const nav = useNavigation();
  // States
  const user = useSelector(selectUser);
  // States
  const [modalVisible, setModalVisible] = React.useState(false);
  const [modalContent, setModalContent] = React.useState({
    title: "",
    description: "",
    buttons: [],
  });
  // Functions
  const handleSeeTutorProfile = () => {
    alert("Coming soon");
  };
  const handleEditPublication = () => {
    nav.navigate("EditPublicationScreen", {
      key: `EditPublicationScreen-${Date.now()}`,
      Publication,
    });
  };
  const handleContactUser = () => {
    const isMobile = Platform.OS !== "web";
    const phoneNumber = Publication?.authorDetails?.userId?.phoneNumber;
    const email = Publication?.authorDetails?.userId?.email;
    console.log("nhjklhjk");
    if (isMobile && phoneNumber) {
      // Utiliser le numéro de téléphone sur mobile s'il est disponible
      Linking.openURL(`tel:${phoneNumber}`);
    } else if (email) {
      // Utiliser l'e-mail si le numéro de téléphone n'est pas disponible sur mobile
      Linking.openURL(`mailto:${email}`);
    } else {
      // Si ni le numéro de téléphone ni l'e-mail ne sont disponibles, afficher un message d'erreur ou prendre une autre mesure
      console.error("Aucun numéro de téléphone ni e-mail disponible.");
    }

    // Permet d'ouvrir le modal de confirmation
    showModal(
      "Contactez-nous",
      `Voulez-vous vraiment nous contacter?\nNuméro de téléphone: ${phoneNumber}\nEmail: ${email}`,
      [
        {
          text: "Annuler",
          onPress: () => {
            setModalVisible(false);
          },
        },
      ]
    );
  };

  // Permet de donner le signal au composant Modal de s'afficher
  const showModal = (title, description, buttons) => {
    setModalContent({ title, description, buttons, nav }); // Ajoutez nav ici
    setModalVisible(true);
  };
  // Permet de donner le signal au composant Modal de se cacher
  const hideModal = () => {
    setModalVisible(false);
  };
  return (
    <View>
      {/* Header */}
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        {/* User Data section */}
        <TouchableOpacity
          onPress={handleSeeTutorProfile}
          style={{
            paddingVertical: 10,
            flexDirection: "row",
            alignItems: "center",
          }}>
          <Image
            source={{
              uri:
                process.env.NODE_ENV === "development"
                  ? `${process.env.URL_IMAGES_DEV}${Publication?.authorDetails?.userId?.profilePicture}`
                  : `${process.env.URL_IMAGES_PROD}${Publication?.authorDetails?.userId?.profilePicture}`,
            }}
            style={{
              width: 75,
              height: 75,
              borderRadius: 50,
              resizeMode: "cover",
              marginRight: 10,
            }}
          />
          <View>
            <Text
              style={{
                fontWeight: "bold",
                fontSize: 16,
              }}>
              {Publication?.authorDetails?.userId?.username}
            </Text>
            {Publication?.authorDetails?.userId?.fullName && (
              <Text
                style={{
                  fontSize: 16,
                }}>
                {Publication?.authorDetails?.userId?.fullName}
              </Text>
            )}
            <Text
              style={{
                fontSize: 16,
                color: "#5D5DFF",
                fontWeight: "bold",
              }}>
              {Publication?.authorDetails?.userId?.role}
            </Text>
          </View>
        </TouchableOpacity>
        <View>
          <Link label="Contacter" onPress={handleContactUser} />
          {editButton === true &&
            (user._id === Publication?.authorDetails?.userId?._id ||
              user.role === "admin") && (
              <Button label={"Modifier"} onPress={handleEditPublication} />
            )}
        </View>
      </View>
      <Modal
        isVisible={modalVisible}
        title={modalContent.title}
        description={modalContent.description}
        buttons={modalContent.buttons}
        onClose={hideModal}
      />
    </View>
  );
};
export default PublicationHeader;
