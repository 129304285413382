// Externals Imports
import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  Dimensions,
  FlatList,
  Platform,
  ActivityIndicator,
  SafeAreaView,
  TouchableOpacity,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
// Internal Imports
import { selectPublications, setPublications } from "../redux/publicationSlice";
import { getPublications } from "../../../controllers/publicationFunctions";
import Publication from "../components/Publication";
import DirectionButton from "../../../components/Buttons/DirectionButtion";
import { selectUser } from "../../../mainRedux/slices/userSlice";
import Modal from "../../../components/Modal/Modal";
// HomeScreen Component
const HomeScreen = () => {
  // Objects
  const nav = useNavigation();
  const width = Dimensions.get("window").width;
  const dispatch = useDispatch();
  // States
  const publications = useSelector(selectPublications);
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector(selectUser);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    description: "",
    buttons: [],
  });

  // Functions
  useEffect(() => {
    fetchData();
    if (!user.fullName || !user.phoneNumber) {
      const alerts = {
        title: "Complétez votre profil",
        description:
          "Pour pouvoir utiliser l'application, vous devez compléter votre profil ou le faire plus tard",
        buttons: [],
      };
      if (!user.fullName) {
        alerts.description += "\n- Nom et prénom";
      }
      if (!user.phoneNumber) {
        alerts.description += "\n- Numéro de téléphone";
      }
      alerts.buttons.push({
        text: "Plus tard",
        onPress: () => {
          setIsModalVisible(false);
        },
      });
      alerts.buttons.push({
        text: "Compléter",
        onPress: () => {
          setIsModalVisible(false);
          nav.navigate("ProfileScreen");
        },
      });
      setModalContent(alerts);
      setIsModalVisible(true);
    }
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    const res = await getPublications();
    if (res.success) {
      dispatch(setPublications(res.data));
    }
    setIsLoading(false);
  };

  // Modal Functions
  const hideModal = () => {
    setIsModalVisible(false);
  };

  const showModal = (title, description, buttons) => {
    setModalContent({ title, description, buttons });
    setIsModalVisible(true);
  };

  const renderButtons = () => {
    return modalContent.buttons.map((button, index) => (
      <TouchableOpacity
        key={index}
        onPress={() => {
          button.onPress && button.onPress();
          hideModal();
        }}
        style={{
          padding: 10,
          margin: 5,
          backgroundColor: "#5D5DFF",
          borderRadius: 5,
        }}>
        <Text style={{ color: "#fff", fontWeight: "700" }}>{button.text}</Text>
      </TouchableOpacity>
    ));
  };

  return (
    <SafeAreaView
      style={{
        flex: 1,
        backgroundColor: "#fff",
        alignItems: "center",
        paddingHorizontal: 20,
        justifyContent: "center",
      }}>
      <View
        style={{
          width: `${
            width > 1000 ? 50 : width > 800 ? 70 : width > 600 ? 90 : 100
          }%`,
          height: "100%",
          paddingTop: 30,
        }}>
        {/* Publicités pas encore développées */}
        <View></View>
        {/* Liste des publications */}
        <FlatList
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          data={publications}
          refreshing={isLoading}
          onRefresh={fetchData}
          ListEmptyComponent={() => (
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "#5D5DFF",
                }}>
                Aucune publication
              </Text>
            </View>
          )}
          renderItem={({ item }) => <Publication Publication={item} />}
          keyExtractor={(item) => item._id}
        />
      </View>
      {Platform.OS === "web" && (
        <DirectionButton
          icon={
            isLoading ? (
              <ActivityIndicator size={20} color="#fff" />
            ) : (
              <Feather name="refresh-ccw" size={24} color="white" />
            )
          }
          insets={{
            top: 10,
            right: 10,
          }}
          onPress={fetchData}
        />
      )}
      <Modal
        isVisible={isModalVisible}
        title={modalContent.title}
        description={modalContent.description}
        buttons={modalContent.buttons}
        onClose={hideModal}
      />
    </SafeAreaView>
  );
};

export default HomeScreen;
