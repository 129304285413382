// External Imports
import React from "react";
import {
  View,
  Text,
  Platform,
  Image,
  SafeAreaView,
  Dimensions,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import Toast from "react-native-toast-message";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { useDispatch } from "react-redux";
// Internal Imports
import InputText from "../../../components/Inputs/InputText";
import Button from "../../../components/Buttons/Button";
import Logo from "../../../assets/icon.png";
import Link from "../../../components/Buttons/Link";
import { tryRegister } from "../controllers/authController";
import SecureLocalStorageManager from "../../../utils/localStorageManager";
import { setUser } from "../../../mainRedux/slices/userSlice";
// LoginScreen Component
const LoginScreen = () => {
  // Objects
  const nav = useNavigation();
  const dispatch = useDispatch();
  const { width } = Dimensions.get("window");
  // States
  const [username, setUsername] = React.useState(null);
  const [email, setEmail] = React.useState(null);
  const [password, setPassword] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  // Create refs for the input fields
  const usernameInputRef = React.useRef(null);
  const emailInputRef = React.useRef(null);
  const passwordInputRef = React.useRef(null);
  // Functions
  const handleRegister = async () => {
    if (isLoading) return;
    setIsLoading(true);
    // Send request to server
    const res = await tryRegister(username, email, password);
    // Check if success
    if (res.success) {
      // Save token to local storage
      await SecureLocalStorageManager.set("token", res.token);
      dispatch(setUser(res.user));
      // Show success message
      Toast.show({
        type: "success",
        text1: "Inscription réussie",
        text2: "Vous êtes maintenant inscrit",
        visibilityTime: 1500,
      });
      // After 1.5s navigate to home
      setTimeout(() => {
        nav.navigate("Home");
      }, 1500);
      return setIsLoading(false);
    }
    // Show error message
    Toast.show({
      type: "error",
      text1: res.message,
      visibilityTime: 1500,
    });
    setIsLoading(false);
  };
  const handleGoBack = () => {
    nav.goBack();
  };
  const handleSuivantPress = (nextRef) => {
    // Move focus to the next input field
    if (nextRef && nextRef.current) {
      nextRef.current.focus();
    }
  };
  return (
    <SafeAreaView
      style={{
        flex: 1,
        backgroundColor: "#fff",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}>
      {width > 600 && (
        <Image
          source={process.env.IMAGE_SIDE}
          style={{
            width: "50%",
            height: "100%",
            resizeMode: "cover",
            // Border Radius right
            borderTopRightRadius: 20,
            borderBottomRightRadius: 20,
          }}
        />
      )}
      <KeyboardAwareScrollView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        style={{
          padding: 30,
        }}>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            paddingVertical: 20, // Ajustez la hauteur en fonction de vos besoins
          }}>
          <Image
            source={Logo}
            style={{
              width: 70,
              height: 70,
              resizeMode: "contain",
            }}
          />
          <Text
            style={{
              marginLeft: 10, // Ajustez la marge en fonction de vos besoins
              fontSize: 30,
              fontWeight: "bold",
            }}>
            StudyBridge
          </Text>
        </View>

        <View
          style={{
            flex: 1,
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <InputText
            label={"Nom d'utilisateur"}
            setData={setUsername}
            ref={usernameInputRef}
            onSubmitEditing={() => handleSuivantPress(emailInputRef)}
          />
          <InputText
            label={"E-mail"}
            setData={setEmail}
            ref={emailInputRef}
            onSubmitEditing={() => handleSuivantPress(passwordInputRef)}
          />
          <InputText
            label={"Mot de passe"}
            keyBoardType="password"
            setData={setPassword}
            ref={passwordInputRef} // Pass the ref here
            onSubmitEditing={handleRegister}
          />
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              marginTop: 10,
            }}>
            <Link label={"Vous avez deja un compte ?"} onPress={handleGoBack} />
          </View>
          <Button
            label={"Insription"}
            onPress={handleRegister}
            isFullWidth={Platform.OS === "web" ? false : true}
            isLoading={isLoading}
          />
        </View>
      </KeyboardAwareScrollView>
      <Toast position="bottom" />
    </SafeAreaView>
  );
};
export default LoginScreen;
