// Externals Imports
import AsyncStorage from "@react-native-async-storage/async-storage";
import { encode, decode } from "base-64";
// Polyfill global scope (if needed)
if (!global.btoa) {
  global.btoa = encode;
}
if (!global.atob) {
  global.atob = decode;
}
// SecureLocalStorageManager Static Class Getter/Setter/Updater/Remover/Clearer
export default class SecureLocalStorageManager {
  static async set(key, value) {
    try {
      const encryptedValue = btoa(JSON.stringify(value));
      await AsyncStorage.setItem(key, encryptedValue);
    } catch (error) {
      console.error("Error storing item:", error);
    }
  }
  static async get(key) {
    try {
      const encryptedValue = await AsyncStorage.getItem(key);
      if (encryptedValue) {
        const decodedValue = atob(encryptedValue);
        return JSON.parse(decodedValue);
      }
      return null;
    } catch (error) {
      console.error("Error getting item:", error);
      return null;
    }
  }
  static async update(key, value) {
    try {
      const existingData = await SecureLocalStorageManager.get(key);
      if (existingData) {
        const updatedData = { ...existingData, ...value };
        await SecureLocalStorageManager.set(key, updatedData);
        return updatedData;
      } else {
        console.error(`Item with key ${key} not found.`);
        return null;
      }
    } catch (error) {
      console.error("Error updating item:", error);
      return null;
    }
  }
  static async remove(key) {
    try {
      await AsyncStorage.removeItem(key);
    } catch (error) {
      console.error("Error removing item:", error);
    }
  }
  static async clear() {
    try {
      await AsyncStorage.clear();
    } catch (error) {
      console.error("Error clearing storage:", error);
    }
  }
}
