// External Imports
import React, { useState, forwardRef } from "react";
import { TextInput, View, Text } from "react-native";
import { AntDesign, Feather } from "@expo/vector-icons";
import { TouchableOpacity } from "react-native";
// InputText Component
const InputText = forwardRef(
  (
    {
      // Props
      label,
      placeholder,
      firstValue = null,
      keyBoardType = "default",
      setData,
      isDescription = false,
      onSubmitEditing,
      returnKeyType = "done",
    },
    ref
  ) => {
    // States
    const [passwordVisible, setPasswordVisible] = useState(false);
    return (
      <View
        style={{
          width: "100%",
          marginBottom: 20,
        }}>
        <Text
          style={{
            fontWeight: "bold",
            fontSize: 16,
            marginBottom: 5,
          }}>
          {label} :
        </Text>
        <View
          style={{
            width: "100%",
            flexDirection: "row",
          }}>
          <TextInput
            ref={ref}
            placeholder={placeholder || label}
            defaultValue={
              keyBoardType === "numeric" ? firstValue?.toString() : firstValue
            }
            secureTextEntry={keyBoardType === "password" && !passwordVisible}
            onChangeText={setData}
            autoCapitalize="none"
            multiline={isDescription}
            onSubmitEditing={onSubmitEditing}
            keyboardType={
              keyBoardType === "password" ? "default" : keyBoardType
            }
            returnKeyType={isDescription ? "default" : returnKeyType}
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.05)",
              width: "100%",
              padding: 10,
              fontSize: 16,
              height: isDescription ? 100 : 50,
            }}
          />
          {keyBoardType === "password" && (
            <TouchableOpacity
              style={{
                position: "absolute",
                right: 10,
                top: 10,
              }}
              onPress={() => setPasswordVisible(!passwordVisible)}>
              {!passwordVisible ? (
                <AntDesign name="eyeo" size={24} color="black" />
              ) : (
                <Feather name="eye-off" size={24} color="black" />
              )}
            </TouchableOpacity>
          )}
        </View>
      </View>
    );
  }
);
export default InputText;
