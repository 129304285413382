// External Imports
import React from "react";
import { TouchableOpacity, Text } from "react-native";
// Link Component
const Link = ({
  // Props
  label,
  onPress,
  textColor = "#5D5DFF",
}) => {
  // Component
  return (
    <TouchableOpacity onPress={onPress}>
      <Text
        style={{
          color: textColor,
          fontWeight: "bold",
          fontSize: 16,
        }}>
        {label}
      </Text>
    </TouchableOpacity>
  );
};
export default Link;
