// Externals Imports
import axios from "axios";
// Internals Imports
import SecureLocalStorageManager from "../utils/localStorageManager";
// Variables
const pubUrl =
  process.env.NODE_ENV === "development"
    ? process.env.URL_DEV
    : process.env.URL_PROD;
// @desc   Get all publications
// @route  GET /api/v1/publications
export const getPublications = async (query) => {
  try {
    const uri = query
      ? `${pubUrl}/publications?${query}`
      : `${pubUrl}/publications`;
    // Get Token And send Request
    const token = await SecureLocalStorageManager.get("token");
    const res = await axios.get(uri, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};
// @desc   Get all publications
// @route  GET /api/v1/publications
export const updatePublication = async (newData, id) => {
  try {
    // Get Token And send Request
    const token = await SecureLocalStorageManager.get("token");
    const filter = {};
    if (newData.title) filter.title = newData.title;
    if (newData.description) filter.description = newData.description;
    if (newData.cost || newData.paymentMethod) filter.pricing = {};
    if (newData.cost) filter.pricing.cost = newData.cost;
    if (newData.paymentMethod)
      filter.pricing.paymentMethod = newData.paymentMethod;

    const res = await axios.put(`${pubUrl}/publications/${id}`, filter, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

// @desc   Delete publication
// @route  DELETE /api/v1/publications/:id
export const deletePublication = async (id) => {
  try {
    // Get Token And send Request
    const token = await SecureLocalStorageManager.get("token");
    const res = await axios.delete(`${pubUrl}/publications/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};
