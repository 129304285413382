// Externals Imports
import React, { useRef, useState, useEffect } from "react";
import { View, Text, SafeAreaView, Platform, Dimensions } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import Toast from "react-native-toast-message";
import { AntDesign } from "@expo/vector-icons";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useNavigation } from "@react-navigation/native";
// Internal Imports
import Button from "../../../../components/Buttons/Button";
import InputText from "../../../../components/Inputs/InputText";
import PublicationHeader from "../../components/PublicationHeader";
import {
  deletePublication,
  updatePublication,
} from "../../../../controllers/publicationFunctions";
import DirectionButton from "../../../../components/Buttons/DirectionButtion";
// EditPublicationScreen Component
const EditPublicationScreen = ({ route }) => {
  // Params
  const { Publication } = route.params;
  // Objects
  const width = Dimensions.get("window").width;
  const nav = useNavigation();
  const percentageWidth =
    width > 1000 ? 50 : width > 800 ? 70 : width > 600 ? 90 : 100;
  const insets = useSafeAreaInsets();
  // States
  const [title, setTitle] = useState(Publication?.title || null);
  const [description, setDescription] = useState(
    Publication?.description || null
  );
  const [cost, setCost] = useState(Publication?.pricing?.cost || null);
  const [paymentMethod, setPaymentMethod] = useState(
    Publication?.pricing?.paymentMethod || null
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  // Refs
  const titleRef = useRef(null);
  const descriptionRef = useRef(null);
  const costRef = useRef(null);
  const paymentMethodRef = useRef(null);
  // Functions
  useEffect(() => {
    const unsubscribe = nav.addListener("blur", () => {
      // Réinitialisez l'état de la publication lorsque l'écran perd le focus
      // Cela déclenchera également le rechargement des données sur l'écran Home
      nav.reset({ routes: [{ name: "Home" }] });
    });
    return unsubscribe;
  }, [nav]);
  const handleEditPublication = async () => {
    if (isLoading) return;
    setIsLoading(true);
    // Try to edit publication
    const res = await updatePublication(
      {
        title,
        description,
        cost,
        paymentMethod,
      },
      Publication._id
    );
    setIsLoading(false);
    // Check if success
    if (res.success) {
      Toast.show({
        type: "success",
        text1: res.message,
        visibilityTime: 3000,
        autoHide: true,
      });
      return;
    }
    // Check if error
    Toast.show({
      type: "error",
      text1: res.message,
      visibilityTime: 3000,
      autoHide: true,
    });
  };
  const handleDeletePublication = async () => {
    if (isDeleting) return;
    setIsDeleting(true);
    // Try to delete publication
    const res = await deletePublication(Publication._id);
    setIsDeleting(false);
    // Check if success
    if (res.success) {
      Toast.show({
        type: "success",
        text1: res.message,
        visibilityTime: 3000,
        autoHide: true,
      });
      // Await 3 seconds
      await new Promise((resolve) => setTimeout(resolve, 1000));
      // Go back to home
      nav.goBack();
      return;
    }
    // Check if error
    Toast.show({
      type: "error",
      text1: res.message,
      visibilityTime: 3000,
      autoHide: true,
    });
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
      <KeyboardAwareScrollView
        enableOnAndroid={true}
        enableAutomaticScroll={true}
        contentContainerStyle={{
          flex: 1,
          padding: 30,
          width: "100%",
        }}>
        <View
          style={{
            flex: 1,
            marginTop: 40,
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}>
          <View
            style={{
              width: `${percentageWidth}%`,
            }}>
            <PublicationHeader Publication={Publication} editButton={false} />
            <Text
              style={{
                fontSize: 30,
                fontWeight: "bold",
                marginBottom: 20,
                color: "#5D5DFF",
              }}>
              Modifier l'annonce
            </Text>
            <InputText
              label="Titre"
              placeholder="Titre"
              keyBoardType="default"
              setData={setTitle}
              ref={titleRef}
              firstValue={title}
              onSubmitEditing={() => costRef.current.focus()}
            />
            <InputText
              label="Prix"
              placeholder="Prix"
              keyBoardType="numeric"
              firstValue={cost}
              setData={setCost}
              ref={costRef}
              onSubmitEditing={() => paymentMethodRef.current.focus()}
            />
            <InputText
              label="Méthode de paiement"
              placeholder="Méthode de paiement"
              keyBoardType="default"
              setData={setPaymentMethod}
              ref={paymentMethodRef}
              firstValue={paymentMethod}
              onSubmitEditing={() => descriptionRef.current.focus()}
            />
            <InputText
              label="Description"
              placeholder="Description"
              keyBoardType="default"
              setData={setDescription}
              ref={descriptionRef}
              firstValue={description}
              isDescription
              onSubmitEditing={handleEditPublication}
            />
            <Button
              label="Modifier l'annonce"
              onPress={handleEditPublication}
              isLoading={isLoading}
            />
            <Button
              label="Supprimer l'annonce"
              onPress={handleDeletePublication}
              backgroundColor="red"
              isLoading={isDeleting}
            />
          </View>
        </View>
      </KeyboardAwareScrollView>
      <Toast position="top" />
      <DirectionButton
        icon={<AntDesign name="leftcircleo" size={24} color="white" />}
        insets={{
          top: insets.top + 10,
          left: insets.left + 10,
        }}
        onPress={() => nav.goBack()}
      />
    </SafeAreaView>
  );
};
export default EditPublicationScreen;
