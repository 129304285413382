// Auth Controller
// External Imports
import axios from "axios";
// Variables
const authURL =
  process.env.NODE_ENV === "development"
    ? process.env.URL_DEV
    : process.env.URL_PROD;

// @desc    Login user
// @route   POST /URL/auth/login
export const tryLogin = async (identifier, password) => {
  try {
    // Check if identifier is email or username
    if (!identifier || !password || identifier === "" || password === "")
      return {
        success: false,
        message: "Veuillez remplir tous les champs",
      };
    // Send request to auth service
    const res = await axios.post(`${authURL}/auth/login`, {
      identifier,
      password,
    });
    // Return response
    return res.data;
  } catch (error) {
    // Return error
    return error.response.data;
  }
};
// @desc    Register user
// @route   POST /URL/auth/register
export const tryRegister = async (username, email, password) => {
  try {
    // Send request to auth service
    const res = await axios.post(`${authURL}/auth/register`, {
      email,
      password,
      username,
    });
    // Return response
    return res.data;
  } catch (error) {
    // Return error
    return error.response.data;
  }
};
