// Externals Imports
import React from "react";
import { Text, TouchableOpacity } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
// DirectionButton Component
const DirectionButton = ({ icon, insets, onPress }) => {
  // Objects
  const insetss = useSafeAreaInsets();
  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        top: insets.top ? insetss.top + 10 : null,
        bottom: insets.bottom ? insetss.bottom + 10 : null,
        right: insets.right ? insetss.right + 10 : null,
        left: insets.left ? insetss.left + 10 : null,
        position: "absolute",
        backgroundColor: "#5D5DFF",
        borderRadius: 50,
        padding: 10,
      }}>
      <Text>{icon}</Text>
    </TouchableOpacity>
  );
};
export default DirectionButton;
