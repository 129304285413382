// Externals imports
import React from "react";
import { Text, TouchableOpacity } from "react-native";
import { ActivityIndicator } from "react-native";
// Button Component
const Button = ({
  // Props
  label,
  onPress,
  backgroundColor = "#5D5DFF",
  textColor = "#fff",
  isFullWidth = false,
  isLoading,
}) => {
  // Component
  return (
    <TouchableOpacity
      style={{
        backgroundColor: backgroundColor,
        width: isFullWidth ? "100%" : "auto",
        padding: 10,
        alignItems: "center",
        justifyContent: "center",
        marginTop: 10,
      }}
      onPress={onPress}>
      <Text
        style={{
          color: textColor,
          fontWeight: "bold",
          fontSize: 16,
        }}>
        {isLoading ? <ActivityIndicator color="#fff" /> : label}
      </Text>
    </TouchableOpacity>
  );
};
export default Button;
