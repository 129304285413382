// External Imports
import React, { useEffect } from "react";
import {
  View,
  Text,
  SafeAreaView,
  Image,
  Platform,
  Linking,
  Dimensions,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { useDispatch } from "react-redux";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
// Internal Imports
import InputText from "../../../components/Inputs/InputText";
import Button from "../../../components/Buttons/Button";
import Logo from "../../../assets/icon.png";
import Link from "../../../components/Buttons/Link";
import { tryLogin } from "../controllers/authController";
import SecureLocalStorageManager from "../../../utils/localStorageManager";
import { setUser } from "../../../mainRedux/slices/userSlice";
import Toast from "react-native-toast-message";
import { getMe } from "../../../controllers/meController";
// LoginScreen Component
const LoginScreen = () => {
  // Objects
  const nav = useNavigation();
  const dispatch = useDispatch();
  const { width } = Dimensions.get("window");
  // States
  const [identifier, setIdentifier] = React.useState(null);
  const [password, setPassword] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  // Create refs for the password input
  const passwordInputRef = React.useRef(null);
  // Functions
  useEffect(() => {
    const checkToken = async () => {
      // Si web
      if (Platform.OS === "web") {
        // Verify if token is on url
        if (window.location.href.includes("token=")) {
          // Get token from url
          const token = window.location.href.split("token=")[1];
          // Save token to local storage
          await SecureLocalStorageManager.set("token", token);
          // Remove token from url
          window.history.replaceState({}, document.title, "/");
        }
      }
      // Try to get user
      const res = await getMe();
      if (res.success) {
        dispatch(setUser(res.user));
        nav.navigate("Home");
      }
    };
    checkToken();
  }, []);
  const handleLogin = async () => {
    if (isLoading) return;
    setIsLoading(true);
    // Send request to server
    const res = await tryLogin(identifier, password);
    // Check if success
    if (res.success) {
      // Save token to local storage
      await SecureLocalStorageManager.set("token", res.token);
      // Dispatch User
      dispatch(setUser(res.user));
      // Show success message
      Toast.show({
        type: "success",
        text1: "Connexion réussie",
        text2: "Vous êtes maintenant connecté",
        visibilityTime: 1500,
      });
      // After 1.5s navigate to home
      setTimeout(() => {
        nav.navigate("Home");
      }, 1500);
      return setIsLoading(false);
    }
    // Show error message
    Toast.show({
      type: "error",
      text1: res.message,
      visibilityTime: 3000,
    });
    setIsLoading(false);
  };
  const handleRegister = () => {
    nav.navigate("Register");
  };
  const handleSuivantPress = () => {
    // Move focus to the password input
    if (passwordInputRef.current) {
      passwordInputRef.current.focus();
    }
  };
  return (
    <SafeAreaView
      style={{
        flex: 1,
        backgroundColor: "#fff",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}>
      {width > 600 && (
        <Image
          source={process.env.IMAGE_SIDE}
          style={{
            width: "50%",
            height: "100%",
            resizeMode: "cover",
            // Border Radius right
            borderTopRightRadius: 20,
            borderBottomRightRadius: 20,
          }}
        />
      )}
      <KeyboardAwareScrollView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        style={{
          padding: 30,
        }}>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            paddingVertical: 20, // Ajustez la hauteur en fonction de vos besoins
          }}>
          <Image
            source={Logo}
            style={{
              width: 70,
              height: 70,
              resizeMode: "contain",
            }}
          />
          <Text
            style={{
              marginLeft: 10, // Ajustez la marge en fonction de vos besoins
              fontSize: 30,
              fontWeight: "bold",
            }}>
            StudyBridge
          </Text>
        </View>

        <View
          style={{
            flex: 1,
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <InputText
            label={"Identifiant"}
            placeholder={"E-mail ou Nom d'utilisateur"}
            firstValue={identifier}
            setData={setIdentifier}
            onSubmitEditing={handleSuivantPress}
          />
          <InputText
            label={"Mot de passe"}
            keyBoardType="password"
            setData={setPassword}
            ref={passwordInputRef} // Pass the ref here
            onSubmitEditing={handleLogin}
          />
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              marginTop: 10,
            }}>
            <Link label={"S'inscrire"} onPress={handleRegister} />
            <Link
              label={"Mot de passe oublié"}
              onPress={() => Linking.openURL(process.env.FORGOT_PASSWORD_LINK)}
            />
          </View>
          <Button
            label={"Se connecter"}
            onPress={handleLogin}
            isFullWidth={Platform.OS === "web" ? false : true}
            isLoading={isLoading}
          />
        </View>
      </KeyboardAwareScrollView>
      <Toast position="bottom" />
    </SafeAreaView>
  );
};

export default LoginScreen;
