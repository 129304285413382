// UserSlice
// External imports
import { createSlice } from "@reduxjs/toolkit";
// Initial state
const initialState = {
  user: null,
};
// Create slice
const userSlice = createSlice({
  name: "user",
  initialState,
  // Reducers
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    updateUsername: (state, action) => {
      state.user.username = action.payload;
    },
    removeUser: (state) => {
      state.user = null;
    },
  },
});
// Export actions
export const { setUser, removeUser, updateUsername } = userSlice.actions;
// Export Selectors
export const selectUser = (state) => state.user.user;
// Export reducer
export default userSlice.reducer;
