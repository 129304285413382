// Externals Imports
import { createSlice } from "@reduxjs/toolkit";
// Initial State
const initialState = {
  publications: [],
};
// Slice
const publicationsSlice = createSlice({
  name: "publications",
  initialState,
  reducers: {
    // Actions
    setPublications: (state, action) => {
      state.publications = action.payload;
    },
    addPublication: (state, action) => {
      // Chercher la publication par son id
      const index = state.publications.findIndex(
        (publication) => publication._id === action.payload._id
      );
      // Si la publication n'existe pas
      if (index === -1) {
        // Ajouter la publication au tableau
        state.publications.push(action.payload);
      } else {
        // Sinon, remplacer la publication par la nouvelle
        state.publications[index] = action.payload;
      }
    },
    deletePublication: (state, action) => {
      // Chercher la publication par son id
      const index = state.publications.findIndex(
        (publication) => publication._id === action.payload
      );
      // Si la publication existe
      if (index !== -1) {
        // Supprimer la publication du tableau
        state.publications.splice(index, 1);
      }
    },
  },
});
// Actions
export const { setPublications, addPublication, deletePublication } =
  publicationsSlice.actions;
// Selectors
export const selectPublications = (state) => state.publications.publications;
// Reducer
export default publicationsSlice.reducer;
