// Externals imports
import React from "react";
import { SafeAreaView, Platform } from "react-native";
import Toast from "react-native-toast-message";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
// Internals imports
import ProfileHeader from "../components/ProfileHeader";
import ProfileEditBody from "../components/ProfileEditBody";
import { Dimensions } from "react-native";
// ProfileScreen Component
const ProfileScreen = () => {
  // Objects
  const width = Dimensions.get("window").width;
  // Plus la largeur est grande, plus le pourcentage est petite mais avec un calcul dynamique
  const percentageWidth =
    width > 1000 ? 50 : width > 800 ? 70 : width > 600 ? 90 : 100;
  return (
    <SafeAreaView
      style={{
        flex: 1,
        backgroundColor: "#fff",
        alignItems: "center",
      }}>
      <KeyboardAwareScrollView
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
        }}
        keyboardShouldPersistTaps="handled"
        enableOnAndroid={true}
        extraHeight={Platform.select({ android: 150, ios: 100 })}
        enableAutomaticScroll={Platform.OS === "ios"}
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        style={{
          width: `${percentageWidth}%`,
          paddingTop: 30,
        }}
        showsVerticalScrollIndicator={false}>
        {/* Profile Header */}
        <ProfileHeader />
        {/* Profile Body Edit */}
        <ProfileEditBody />
      </KeyboardAwareScrollView>
      <Toast position="top" />
    </SafeAreaView>
  );
};
export default ProfileScreen;
