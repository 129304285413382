// Externals Imports
import { createStackNavigator } from "@react-navigation/stack";
// Internals Imports
import LoginScreen from "./screens/LoginScreen";
import RegisterScreen from "./screens/RegisterScreen";
import HomeTab from "../home/HomeStack";
import { View } from "react-native";
// Create Stack Navigator
const Stack = createStackNavigator();
// Create AuthStack
const AuthStack = () => {
  return (
    <View style={{ height: "99.8%" }}>
      <Stack.Navigator
        screenOptions={{
          headerShown: false,
        }}
        initialRouteName="Login">
        <Stack.Screen name="Login" component={LoginScreen} />
        <Stack.Screen name="Register" component={RegisterScreen} />
        <Stack.Screen name="Home" component={HomeTab} />
      </Stack.Navigator>
    </View>
  );
};
// Export AuthStack
export default AuthStack;
