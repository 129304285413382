// Externals Imports
import React from "react";
import { View, Text, FlatList, Platform } from "react-native";
// Internal Imports
import PublicationHeader from "./PublicationHeader";
// Publication Component
const Publication = ({ Publication }) => {
  return (
    <View
      style={{
        width: "100%",
        paddingVertical: 10,
        backgroundColor: "#f2f2f2",
        paddingHorizontal: 10,
        paddingRight: 20,
        marginBottom: 20,
      }}>
      {/* Header Publication */}
      <PublicationHeader Publication={Publication} />
      {/* Body */}
      <View>
        <Text
          style={{
            fontSize: 20,
            marginVertical: 5,
            color: "#5D5DFF",
            fontWeight: "bold",
          }}>
          Annonce :
        </Text>
        <Text
          style={{
            fontSize: 20,
            marginVertical: 5,
          }}>
          {Publication?.title}
          {"   "}
          {Publication?.pricing &&
            `${Publication?.pricing?.cost}$ ${
              Publication?.pricing?.paymentMethod || ""
            }`}
        </Text>
        <Text
          style={{
            fontSize: 16,
            marginVertical: 5,
          }}>
          {Publication?.description}
        </Text>
      </View>
      {/* Categories */}
      <FlatList
        horizontal={true}
        showsHorizontalScrollIndicator={Platform.OS === "web" ? true : false}
        data={Publication.categoryDetails}
        keyExtractor={(item) => item._id}
        renderItem={({ item }) => {
          return (
            <View
              style={{
                backgroundColor: "#5D5DFF",
                paddingVertical: 5,
                paddingHorizontal: 10,
                borderRadius: 20,
                marginRight: 10,
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Text
                style={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: 16,
                }}>
                {item.name}
              </Text>
            </View>
          );
        }}
      />
    </View>
  );
};
export default Publication;
