// Externals Imports
import {
  Text,
  SafeAreaView,
  FlatList,
  TouchableOpacity,
  Linking,
  Platform,
} from "react-native";
import React, { useState } from "react";
import { useNavigation } from "@react-navigation/native";
// Internal Imports
import Modal from "../../../components/Modal/Modal";
// OptionsScreen Component
const options = [
  {
    key: "Cour Particulier",
    description:
      "Cours particulier pour un soutien scolaire optimal et personnalisé choisissez votre enseignant et votre matière",
    value: "ParticulierScreen",
    buttons: [
      {
        text: "Annuler",
        onPress: (hideModal) => hideModal(),
      },
      {
        text: "Contactez-nous",
        onPress: (hideModal) => {
          hideModal();
          Linking.openURL(
            Platform.OS === "web"
              ? "mailto:aiacheamine2004@gmail.com"
              : "tel:5145687763"
          );
        },
      },
    ],
  },
  {
    key: "Cours Groupe",
    description:
      "- Cours en groupe pour un soutien scolaire optimal et personnalisé choisissez votre enseignant et votre matière\n- Cette fonctionnalité est disponible dans la page d'accueil si vous souhaitez rejoindre un groupe existant\n- Vous pouvez aussi créer votre propre groupe et inviter vos amis pour cela veuillez nous contacter",
    value: "GroupeScreen",
    buttons: [
      {
        text: "Annuler",
        onPress: (hideModal) => hideModal(),
      },
      {
        text: "Contactez-nous",
        onPress: (hideModal) => {
          hideModal();
          Linking.openURL(
            Platform.OS === "web"
              ? "mailto:aiacheamine2004@gmail.com"
              : "tel:5145687763"
          );
        },
      },
      {
        text: "Page Home",
        onPress: (hideModal, nav) => {
          hideModal();
          nav.navigate("HomeScreen");
        },
        style: "cancel",
      },
    ],
  },
  {
    key: "Aide aux devoirs",
    description:
      "Aide aux devoirs , faire un ou plusieurs exercices avec un enseignant ou juste afin de vérifier vos réponses ou de les fournir",
    value: "DevoirsScreen",
    buttons: [
      {
        text: "Annuler",
        onPress: (hideModal) => hideModal(),
      },
      {
        text: "Contactez-nous",
        onPress: (hideModal) => {
          hideModal();
          Linking.openURL(
            Platform.OS === "web"
              ? "mailto:aiacheamine2004@gmail.com"
              : "tel:5145687763"
          );
        },
      },
    ],
  },
];
// OptionsScreen Component
const OptionsScreen = () => {
  // Objects
  const nav = useNavigation();
  // States
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    description: "",
    buttons: [],
  });
  // Functions
  const showModal = (title, description, buttons) => {
    setModalContent({ title, description, buttons, nav }); // Ajoutez nav ici
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };

  const handleOptionPress = (item) => {
    showModal(
      item.key,
      item.description,
      item.buttons.map((btn) => ({
        ...btn,
        onPress: () => {
          btn.onPress && btn.onPress(hideModal, nav);
        },
      }))
    );
  };

  // Correction : Retirez les parenthèses pour la référence de la fonction
  const optionComponent = ({ item }) => {
    return (
      <TouchableOpacity
        onPress={() => handleOptionPress(item)}
        style={{
          flex: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          paddingVertical: 20,
          paddingHorizontal: 10,
          backgroundColor: "#F2F2F2",
          marginVertical: 10,
        }}>
        <Text
          style={{
            fontSize: 20,
            fontWeight: "bold",
            color: "#5D5DFF",
            marginBottom: 10,
          }}>
          {item.key}
        </Text>
        <Text>{item.description}</Text>
      </TouchableOpacity>
    );
  };

  return (
    <SafeAreaView
      style={{
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingVertical: 20,
        backgroundColor: "#fff",
      }}>
      <Text
        style={{
          marginLeft: 10,
          fontSize: 30,
          fontWeight: "bold",
          color: "#5D5DFF",
        }}>
        Options
      </Text>
      {/* Correction : Retirez les parenthèses pour la référence de la fonction */}
      <FlatList
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        data={options}
        renderItem={optionComponent}
        keyExtractor={(item) => item.key}
      />
      <Modal
        isVisible={modalVisible}
        title={modalContent.title}
        description={modalContent.description}
        buttons={modalContent.buttons}
        onClose={hideModal}
      />
    </SafeAreaView>
  );
};

export default OptionsScreen;
